import React from 'react';
import YouTube from 'react-youtube';
import './App.css';

class App extends React.Component {
  render() {
    const opts = {
      height: '475',
      width: '800',
      playerVars: { 
        autoplay: 0
      }
    };

    return (
      <div className="App">
        <header className="App-header">
          <p style={{ fontSize: '125%', marginBottom: '0.5%', marginTop: '2%' }}>
            Merry Christmas!
          </p>
          <p style={{ fontSize: '50%', marginTop: '0%', marginBottom: '2%' }}>
            I wanted to give you something extra special this year, so I thought about it.<br/>
            <em>For some reason</em>, you love seeing videos of me and I know you'll miss me when I go to SF.<br/>
            So it was brilliant of me to combine this thought with my nerdy CS skills to make this present for you.<br/><br/>
            
            You're going to get a video from me for every day I'm gone (total of <strong>76</strong>). All you have to do is come back here.<br/>
            I'm not able to be there with you physically, so I hope these help you not miss me <em>too much</em>.<br/>
            I love you so much and don't forget that I'm only one call or text away.<br/>
            <span style={{ fontFamily: 'Courier Prime' }}>- Danny Pham ❤️</span>
          </p>
          <p style={{ fontSize: '50%', marginTop: '0%', marginBottom: '1%' }}>
            Introduction to your Present<br/>
            <em style={{ fontSize: '90%' }}>First video available on January 12, 2020</em>
          </p>
          <YouTube
            videoId=''
            opts={opts}
            onReady={this._onReady}
          />
          <p style={{ fontSize: '50%' }}>
            <a 
              style={{ color: 'white', textDecoration: 'none' }} 
              href='https://www.youtube.com/playlist?list=PL6wnNymaqr6yGK3rckT07tswp_8u-wFXK'
              target='_blank'
              rel='noopener noreferrer'
            >
              <strong>Previous Videos</strong>
            </a>
          </p>
        </header>
      </div>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default App;
